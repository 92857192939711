import React, { FC } from "react";
import { SEO } from "@wealthComponents/seo";
import {
  AuthPage,
  PromoTwo,
  PromoTwoBody,
  useClientAuthComponents,
} from "@ifgengineering/client-auth-components";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import {
  signInAppleAction,
  signInGoogleAction,
  signupAppleAction,
} from "@components/Login/actions";
import { APPLE_CLIENT_ID } from "gatsby-env-variables";
import getAndStoreAppleIdToken from "../../helpers/auth/getAndStoreAppleIdToken";
import { AppleAuthResponseData } from "@ifgengineering/client-auth-components/src/components/Widget/pages/Signin/types";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

// TODO: change name to Auth once we remove all legacy auth
const NewAuth: FC = () => {
  const authBgColor = "linear-gradient(180deg, #192a36, #000000)";

  const dispatch = useDispatch();

  const { setAppleAuthData, appleAuthData } = useClientAuthComponents();

  const handleGoogleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/userinfo.email",
    ux_mode: "popup",
    onSuccess: async (codeRes) =>
      dispatch(signInGoogleAction({ authorizationCode: codeRes.code })),
    onError: (errorResponse: any) => console.log(errorResponse),
  });

  const handleAppleLogin = async (data: AppleAuthResponseData) => {
    const { id_token, code } = data.authorization;
    const { token: appleIdToken } = getAndStoreAppleIdToken(id_token);
    const isTokenAndCodePresent = appleIdToken && code;

    if (isTokenAndCodePresent) {
      setAppleAuthData({ authorizationCode: code });

      try {
        const res = await dispatch(
          signInAppleAction({
            authorizationCode: code,
            appleIdToken: appleIdToken,
          })
          // @ts-expect-error untyped
        ).unwrap();
        const isPrivateEmail = res?.isPrivateRelayEmail;
        if (isPrivateEmail) {
          setAppleAuthData({ isPrivateEmail });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAppleSignup = () => {
    if (appleAuthData?.authorizationCode) {
      const { authorizationCode } = appleAuthData;
      dispatch(signupAppleAction({ authorizationCode }));
    }
  };

  const APPLE_REDIRECT_URI = location.origin;

  return (
    <>
      <SEO title="Islamic Finance Guru" />
      <AuthPage
        Promo={() => <PromoTwo />}
        MobileFooter={() => <PromoTwoBody />}
        showHeader={true}
        showTrustBox={true}
        backgroundColor={authBgColor}
        authRedirect={"/app/calculate"}
        appleLogin={{
          enable: isZakat2025Enabled,
          clientId: APPLE_CLIENT_ID,
          redirectURI: APPLE_REDIRECT_URI,
          onClick: handleAppleLogin,
          onSignup: handleAppleSignup,
        }}
        googleLogin={{
          enable: isZakat2025Enabled,
          onClick: handleGoogleLogin,
        }}
      />
    </>
  );
};

export default NewAuth;
